export const purgePhone = (phone) => {
  const regex = /\d+/;
  let numbersString = "";

  for (const character of phone) {
    if (regex.test(character)) {
      numbersString += character;
    }
  }

  return numbersString;
};

export const removeWhiteSpaces = (phone) => {
  const whitespaceRegex = /\s/g;
  return phone.replace(whitespaceRegex, "");
};

export const millisToMinutesAndSeconds = (millis) => {
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0);
  return seconds === 60
    ? minutes + 1 + ":00"
    : minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
};
