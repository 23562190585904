export const environments = {
  development: {
    baseUrl: "https://api.tapkards.com/dev",
    otra: "...",
  },
  production: {
    baseUrl: "https://api.tapkards.com/dev",
    otra: "...",
  },
};
