import React, { useState, useEffect, createContext } from "react";
import BusinessCard from "./components/cards/BusinessCard";
import LandingPage from "./components/landing/LandingPage";
import LoginPage from "./components/login/LoginPage";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import RootErrorBoundary from "./components/page/RootErrorBoundary";
import NoMatch from "./components/page/NoMatch";
import { Fallback } from "./components/page/RootErrorBoundary";
import Profile from "./components/profile/Profile";
import Checkout from "./components/checkout/Checkout";
import Loader from "./components/page/Loader";
import { millisToMinutesAndSeconds } from "./utils/utils";
import { paymentAttemptTime, sessionTime } from "./config/constants";
import PaymentResult from "./components/page/PaymentResult";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const initalAuthState = {
  loggedIn: false,
  authItem: {},
  status: "idle",
  loading: true,
  snackMessage: {
    display: false,
    message: "",
    type: "info",
  },
};

export const AuthContext = createContext(initalAuthState);
function App() {
  const [auth, setAuth] = useState(initalAuthState);

  useEffect(() => {
    const authInfo = localStorage.getItem("authInfo");
    if (authInfo !== null) {
      const authObject = JSON.parse(authInfo);
      const currentDate = new Date();
      const loginDate = new Date(authObject.loginDate);
      const difference = millisToMinutesAndSeconds(currentDate - loginDate);
      let expired = false;
      if (parseInt(difference.split(":")[0]) >= sessionTime) {
        localStorage.removeItem("authInfo");
        expired = true;
      }
      setAuth((auth) => ({
        ...auth,
        loading: false,
        ...(expired && {
          snackMessage: {
            display: true,
            message: "Your session is expired, please sign in again.",
            type: "error",
          },
        }),
        ...(!expired && { loggedIn: true }),
        ...(!expired && { authItem: authObject }),
      }));
    } else {
      setAuth((auth) => ({
        ...auth,
        loading: false,
      }));
    }
    const paymentInfo = localStorage.getItem("paymentInfo");
    if (paymentInfo !== null) {
      const paymentObject = JSON.parse(paymentInfo);
      const currentDate = new Date();
      const attempDate = new Date(paymentObject.attempDate);
      const difference = millisToMinutesAndSeconds(currentDate - attempDate);
      if (parseInt(difference.split(":")[0]) >= paymentAttemptTime) {
        localStorage.removeItem("paymentInfo");
      }
    }
  }, []);

  let router = createBrowserRouter([
    {
      path: "/",
      element: <LandingPage />,
      errorElement: <RootErrorBoundary />,
    },
    {
      path: "/checkout",
      element: <Checkout />,
      errorElement: <RootErrorBoundary />,
    },
    {
      path: "login",
      element: <LoginPage />,
      errorElement: <RootErrorBoundary />,
    },
    {
      path: "profile",
      element: auth.loggedIn ? (
        <Profile />
      ) : (
        <Navigate to="/login" replace={true} />
      ),
      errorElement: <RootErrorBoundary />,
    },
    {
      path: "business/:id",
      element: <BusinessCard variant="business" />,
      errorElement: <RootErrorBoundary />,
    },
    {
      path: "pet/:id",
      element: <BusinessCard variant="pet" />,
      errorElement: <RootErrorBoundary />,
    },
    {
      path: "payment",
      element: <PaymentResult />,
      errorElement: <RootErrorBoundary />,
    },
    {
      path: "*",
      element: <NoMatch />,
      errorElement: <RootErrorBoundary />,
    },
  ]);

  return (
    <AuthContext.Provider value={{ auth, setAuth, initalAuthState }}>
      {auth.loading && <Loader />}

      {!auth.loading && (
        <RouterProvider router={router} fallbackElement={<Fallback />} />
      )}
    </AuthContext.Provider>
  );
}

export default App;
