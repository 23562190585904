import React, { useEffect, useContext } from "react";
import { CheckoutContext } from "./Checkout";
import Typography from "@mui/material/Typography";
import { Box, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { Autocomplete } from "@react-google-maps/api";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const AddressFormItem = ({
  item,
  index,
  stateItem,
  errors,
  onChange,
  isLoaded,
  onLoad,
  onPlaceChanged,
  address1Ref,
  repeatValue,
  sameAsBusiness,
  allowEditField,
  handleOnblur,
}) => {
  const checkoutContext = useContext(CheckoutContext);

  useEffect(() => {
    if (isLoaded && item.address1 !== "" && address1Ref?.current !== null) {
      address1Ref.current.value = item.address1;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  const handleFocus = (index) => {
    const errors = structuredClone(checkoutContext.checkoutState.errors);
    const addressesErrorList = [...errors.addresses];
    addressesErrorList[index] = {
      ...addressesErrorList[index],
      address1: "",
      zip: "",
      city: "",
      stateAddress: "",
    };
    checkoutContext.setCheckoutState({
      ...checkoutContext.checkoutState,
      errors: {
        ...checkoutContext.checkoutState.errors,
        addresses: [...addressesErrorList],
      },
    });
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      spacing={1}
      sx={{ width: 1, my: 3 }}
    >
      <Stack
        direction={{ xs: "column", lg: "row" }}
        justifyContent="flex-start"
        alignItems={{ xs: "flex-start", lg: "center" }}
        spacing={2}
      >
        <Typography variant="h6" className="item-subtitle" gutterBottom>
          {item.type} Address:
        </Typography>
        {item.type !== "business" && (
          <FormControlLabel
            control={
              <Checkbox
                checked={repeatValue}
                onChange={() => sameAsBusiness(index)}
                size="small"
              />
            }
            label="Use same as Business "
          />
        )}
      </Stack>
      {!repeatValue && (
        <>
          <Stack
            direction={{ xs: "column", lg: "row" }}
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{ width: 1 }}
          >
            <Box sx={{ width: { xs: 1, lg: 0.5 } }}>
              {isLoaded ? (
                <Autocomplete
                  onLoad={onLoad}
                  onPlaceChanged={() => onPlaceChanged(index)}
                  restrictions={{ country: ["us"] }}
                >
                  <TextField
                    size="small"
                    variant="standard"
                    margin="normal"
                    required
                    disabled={repeatValue}
                    label="Address Line 1"
                    className="item-subtitle"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    inputProps={{ ref: address1Ref }}
                    onFocus={() => handleFocus(index)}
                    error={errors[stateItem][index].address1 !== ""}
                    helperText={
                      errors[stateItem][index].address1 ?? "Input Req."
                    }
                    onBlur={() => {
                      handleOnblur(index);
                    }}
                    onChange={(event) => {
                      onChange(event, index, "address1");
                    }}
                  />
                </Autocomplete>
              ) : (
                <CircularProgress size="1.5rem" />
              )}
            </Box>
            <TextField
              size="small"
              variant="standard"
              value={item.address2}
              onChange={(event) => {
                onChange(event, index, "address2");
              }}
              disabled={repeatValue}
              sx={{ width: { xs: 1, lg: 0.5 } }}
              label="Address Line 2"
              className="item-subtitle"
              InputLabelProps={{ shrink: true }}
              error={errors[stateItem][index].address2 !== ""}
              helperText={errors[stateItem][index].address2 ?? "Input Req."}
            />
          </Stack>
          <Stack
            direction={{ xs: "column", lg: "row" }}
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{ width: 1 }}
          >
            <TextField
              size="small"
              variant="standard"
              required
              value={item.city}
              disabled={!allowEditField.city || repeatValue}
              onChange={(event) => {
                onChange(event, index, "city");
              }}
              sx={{ width: { xs: 1, lg: 0.34 } }}
              label="City"
              className="item-subtitle"
              InputLabelProps={{ shrink: true }}
              error={errors[stateItem][index].city !== ""}
              helperText={errors[stateItem][index].city ?? "Input Req."}
            />
            <TextField
              size="small"
              variant="standard"
              required
              value={item.stateAddress}
              disabled={!allowEditField.stateAddress || repeatValue}
              onChange={(event) => {
                onChange(event, index, "stateAddress");
              }}
              sx={{ width: { xs: 1, lg: 0.33 } }}
              label="State"
              className="item-subtitle"
              InputLabelProps={{ shrink: true }}
              error={errors[stateItem][index].stateAddress !== ""}
              helperText={errors[stateItem][index].stateAddress ?? "Input Req."}
            />
            <TextField
              size="small"
              variant="standard"
              required
              value={item.zip}
              onChange={(event) => {
                onChange(event, index, "zip");
              }}
              disabled={!allowEditField.zip || repeatValue}
              sx={{ width: { xs: 1, lg: 0.33 } }}
              label="Zip Code"
              className="item-subtitle"
              InputLabelProps={{ shrink: true }}
              error={errors[stateItem][index].zip !== ""}
              helperText={errors[stateItem][index].zip ?? "Input Req."}
            />
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default AddressFormItem;
