import React from "react";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import logo from "../../image/logo.png";
import { useRouteError } from "react-router-dom";
import { Backdrop, Stack } from "@mui/material";

export function Fallback() {
  return <p>Performing initial data "load"</p>;
}

const defaultTheme = createTheme({
  palette: {
    primaryLb: {
      main: "#c2608e",
      light: "#ce7fa4",
      dark: "#874363",
      contrastText: "#fff",
    },
  },
});

export default function RootErrorBoundary() {
  let error = useRouteError();
  return (
    <ThemeProvider theme={defaultTheme}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
        className="footer theme-bg loader"
      >
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Box sx={{ maxWidth: { xs: 0.7, md: "0.4", lg: "0.2" } }}>
            <img src={logo} className="img-fluid" alt="tapkards logo" />
          </Box>
          <Typography
            component="h1"
            variant="h4"
            data-cy="errorTitle"
            sx={{ mt: 2, textAlign: "center" }}
          >
            Whoops! Seems like there <br /> was an little error
          </Typography>
          <Alert severity="error" sx={{ mt: 2 }}>
            {error.message || JSON.stringify(error)}
          </Alert>
        </Stack>
      </Backdrop>
    </ThemeProvider>
  );
}
