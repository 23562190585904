import React, { useState, useContext, useEffect, useRef } from "react";
import { AuthContext } from "../../App";
import { baseUrl, emailRegex, singleNameRegex } from "../../config/constants";
import {
  fetchProfile,
  updateAllPets,
  updateImage,
  deletePet,
  additionalBusinessDelete,
  additionalBusinessAdd,
  refreshSubscription,
} from "../../config/api";
import "./style.css";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import ProfileHeaderSection from "./components/ProfileHeaderSection";
import CircularProgress from "@mui/material/CircularProgress";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import logo from "../../image/logo.png";
import ClientForm from "./forms/ClientForm";
import ClientDisplay from "./display/ClientDisplay";
import FloatingActions from "./components/FloatingActions";
import Snackbar from "@mui/material/Snackbar";
import { matchIsValidTel } from "mui-tel-input";
import { removeWhiteSpaces } from "../../utils/utils";
import AddPetForm from "./forms/AddPetForm";
import { useJsApiLoader } from "@react-google-maps/api";
import { libraries } from "../../config/constants";
import Loader from "../page/Loader";

const defaultTheme = createTheme({
  palette: {
    primaryTk: {
      main: "#33428C",
      light: "#5b67a3",
      dark: "#232e62",
      contrastText: "#fff",
    },
    white: {
      main: "#fff",
    },
    chipW: {
      main: "#ffb300",
      light: "#ffc233",
      dark: "#b27d00",
      contrastText: "#fff",
    },
  },
});

const initialErrors = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  phoneListSection: "",
  emailListSection: "",
  mediaListSection: "",
  addressesSection: "",
  phoneList: [],
  emailList: [],
  mediaList: [],
  addresses: [],
  pets: [],
};

const blankEditAddress = {
  address1: true,
  address2: true,
  zip: false,
  city: false,
  stateAdress: false,
};

const blankAddress = {
  address1: "",
  address2: "",
  zip: "",
  city: "",
  stateAddress: "",
};

const addressError = {
  address1: "",
  address2: "",
  zip: "",
  city: "",
  stateAddress: "",
};

const initialErrorsCheckout = {
  client: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  },
  pets: [],
  payment: {
    cardName: "",
    cardNumber: "",
    expiry: "",
    cvv: "",
  },
  addresses: [addressError, addressError, addressError],
};

const initialCheckoutState = {
  prices: {
    new: 0,
    renew: 0,
    additional: 0,
    replacement: 0,
  },
  products: [
    {
      type: "businessOnly",
      businessCard: true,
      businessQty: 1,
      petCard: false,
      petQty: 0,
      price: 0,
      label: "Business Card",
      added: false,
    },
    {
      type: "petsOnly",
      businessCard: false,
      businessQty: 0,
      petCard: true,
      petQty: 1,
      price: 0,
      label: "Pet Card",
      added: true,
    },
  ],
  client: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  },
  pets: [],
  addresses: [
    { ...blankAddress, type: "business" },
    { ...blankAddress, type: "shipping" },
    { ...blankAddress, type: "billing" },
  ],
  payment: {
    cardName: "",
    cardNumber: "",
    expiry: "",
    cvv: "",
  },
  termsCheck: false,
  addressRepeat: [false, false, false],
  allowEditAdresses: [
    { ...blankEditAddress },
    { ...blankEditAddress },
    { ...blankEditAddress },
  ],
  searchedAdresses: [false, false, false],
  errors: initialErrorsCheckout,
  snackMessage: {
    display: false,
    message: "",
    type: "info",
  },
};

export const ProfileContext = React.createContext(initialCheckoutState);

const Profile = () => {
  const authContext = useContext(AuthContext);

  const [state, setState] = useState({
    loading: true,
    requesting: false,
    editing: false,
    paying: false,
    subscriptionId: "",
    payingItem: {},
    data: {},
    client: {},
    clientEdit: {},
    addressRepeat: [false, false, false],
    allowEditAdresses: [
      { ...blankEditAddress },
      { ...blankEditAddress },
      { ...blankEditAddress },
    ],
    mediaTypes: [],
    error: false,
    errorImage: false,
    message: "",
    snackMessage: {
      display: false,
      message: "",
      type: "info",
    },
    messageImage: "",
    errors: initialErrors,
    pets: [],
    petsEdit: [],
    subscriptions: [],
    hasBuiness: true,
    blockAll: false,
  });

  const [checkoutState, setCheckoutState] = useState(initialCheckoutState);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: authContext.auth.authItem.decodedJwt.googleApiKey,
    libraries,
  });

  const address11 = useRef(null);
  const address12 = useRef(null);
  const address13 = useRef(null);
  const onLoad1 = React.useCallback(function callback(map) {
    setMap1(map);
  }, []);
  const onLoad2 = React.useCallback(function callback(map) {
    setMap2(map);
  }, []);
  const onLoad3 = React.useCallback(function callback(map) {
    setMap3(map);
  }, []);
  const [map1, setMap1] = React.useState(null);
  const [map2, setMap2] = React.useState(null);
  const [map3, setMap3] = React.useState(null);
  const onloads = [onLoad1, onLoad2, onLoad3];
  const refs = [address11, address12, address13];

  const onPlaceChanged = (index) => {
    const mapObject = index === 0 ? map1 : index === 1 ? map2 : map3;
    if (mapObject.autocomplete !== null && mapObject.getPlace()) {
      const place = mapObject.getPlace();

      if (place.name === "") {
        let address1 = "";
        const clientEditAddresslist = [...checkoutState.addresses];
        clientEditAddresslist[index] = {
          ...clientEditAddresslist[index],
          address1: "",
          address2: "",
          zip: "",
          city: "",
          stateAddress: "",
        };
        const allowEditList = [...checkoutState.allowEditAdresses];
        allowEditList[index] = {
          ...allowEditList[index],
          zip: false,
          city: false,
          stateAddress: false,
        };
        index === 0
          ? (address11.current.value = address1)
          : index === 1
          ? (address12.current.value = address1)
          : (address13.current.value = address1);

        if (index === 0) {
          if (checkoutState.addressRepeat[1]) {
            clientEditAddresslist[1] = {
              ...clientEditAddresslist[1],
              address1: "",
              address2: "",
              zip: "",
              city: "",
              stateAddress: "",
            };
            allowEditList[1] = {
              ...allowEditList[1],
              zip: false,
              city: false,
              stateAddress: false,
            };
            // address12.current.value = address1;
          }
          if (checkoutState.addressRepeat[2]) {
            clientEditAddresslist[2] = {
              ...clientEditAddresslist[2],
              address1: "",
              address2: "",
              zip: "",
              city: "",
              stateAddress: "",
            };
            allowEditList[2] = {
              ...allowEditList[2],
              zip: false,
              city: false,
              stateAddress: false,
            };
            // address13.current.value = address1;
          }
        }

        const searched = [...checkoutState.searchedAdresses];
        searched[index] = false;

        setCheckoutState((checkoutState) => ({
          ...checkoutState,
          addresses: clientEditAddresslist,
          allowEditAdresses: allowEditList,
          searchedAdresses: searched,
        }));
        return;
      }

      let address1 = "";
      let zip = "";
      let city = "";
      let stateAdress = "";

      try {
        for (const component of place.address_components) {
          const componentType = component.types[0];
          switch (componentType) {
            case "street_number":
              address1 = `${component.long_name} ${address1}`;
              break;

            case "route":
              address1 += component.short_name;
              break;

            case "postal_code":
              zip = `${component.long_name}${zip}`;
              break;

            case "postal_code_suffix":
              zip = `${zip}-${component.long_name}`;
              break;

            case "locality":
              city = component.long_name;
              break;

            case "administrative_area_level_1":
              stateAdress = component.short_name;
              break;
            default:
              break;
          }
        }
        const clientEditAddresslist = [...checkoutState.addresses];
        clientEditAddresslist[index] = {
          ...clientEditAddresslist[index],
          address1,
          address2: "",
          zip,
          city,
          stateAddress: stateAdress,
        };
        const allowEditList = [...checkoutState.allowEditAdresses];
        allowEditList[index] = {
          ...allowEditList[index],
          zip: zip === "",
          city: city === "",
          stateAddress: checkoutState === "",
        };
        if (index === 0) {
          if (checkoutState.addressRepeat[1]) {
            clientEditAddresslist[1] = {
              ...clientEditAddresslist[1],
              address1,
              address2: "",
              zip,
              city,
              stateAddress: stateAdress,
            };
            allowEditList[1] = {
              ...allowEditList[1],
              zip: false,
              city: false,
              stateAddress: false,
            };
            // address12.current.value = address1;
          }
          if (checkoutState.addressRepeat[2]) {
            clientEditAddresslist[2] = {
              ...clientEditAddresslist[2],
              address1,
              address2: "",
              zip,
              city,
              stateAddress: stateAdress,
            };
            allowEditList[2] = {
              ...allowEditList[2],
              zip: false,
              city: false,
              stateAddress: false,
            };
            // address13.current.value = address1;
          }
        }

        index === 0
          ? (address11.current.value = address1)
          : index === 1
          ? (address12.current.value = address1)
          : (address13.current.value = address1);
        const searched = [...checkoutState.searchedAdresses];
        searched[index] = true;

        setCheckoutState((checkoutState) => ({
          ...checkoutState,
          addresses: clientEditAddresslist,
          allowEditAdresses: allowEditList,
          searchedAdresses: searched,
        }));
      } catch (error) {
        console.error(error);

        const clientEditAddresslist = [...checkoutState.addresses];
        clientEditAddresslist[index] = {
          ...clientEditAddresslist[index],
          address1: place.name,
          address2: "",
          zip: "",
          city: "",
          stateAddress: "",
        };
        const allowEditList = [...checkoutState.allowEditAdresses];
        allowEditList[index] = {
          ...allowEditList[index],
          zip: true,
          city: true,
          stateAddress: true,
        };
        index === 0
          ? (address11.current.value = place.name)
          : index === 1
          ? (address12.current.value = place.name)
          : (address13.current.value = place.name);

        if (index === 0) {
          if (checkoutState.addressRepeat[1]) {
            clientEditAddresslist[1] = {
              ...clientEditAddresslist[1],
              address1: place.name,
              address2: "",
              zip: "",
              city: "",
              stateAddress: "",
            };
            allowEditList[1] = {
              ...allowEditList[1],
              zip: true,
              city: true,
              stateAddress: true,
            };
            // address12.current.value = address1;
          }
          if (checkoutState.addressRepeat[2]) {
            clientEditAddresslist[2] = {
              ...clientEditAddresslist[2],
              address1: place.name,
              address2: "",
              zip: "",
              city: "",
              stateAddress: "",
            };
            allowEditList[2] = {
              ...allowEditList[2],
              zip: true,
              city: true,
              stateAddress: true,
            };
            // address13.current.value = address1;
          }
        }
        const searched = [...checkoutState.searchedAdresses];
        searched[index] = true;

        setCheckoutState((checkoutState) => ({
          ...checkoutState,
          addresses: clientEditAddresslist,
          allowEditAdresses: allowEditList,
          searchedAdresses: searched,
        }));
        return;
      }
    }
  };

  const [openNewPet, setOpenNewPet] = React.useState(false);

  const [openNewBusiness, setOpenNewBusiness] = React.useState(false);
  const [requestingBusiness, setRequestingBusiness] = React.useState(false);
  const [openCancelBusiness, setOpenCancelBusiness] = React.useState(false);

  const [openCancelPet, setOpenCancelPet] = React.useState(false);
  const [requestingCancelPet, setRequestingCancelPet] = React.useState(false);
  const [petIndexCancel, setPetIndexCancel] = React.useState("");

  const handleDeletePet = async () => {
    const token = authContext.auth.authItem.accessToken;
    if (requestingCancelPet || petIndexCancel === "") {
      return;
    }
    setRequestingCancelPet(true);
    const pet = state.pets[petIndexCancel];
    const response = await deletePet(pet.petId, token);
    if (response.success) {
      const currentPets = [...state.pets];
      currentPets.splice(petIndexCancel, 1);
      setState((state) => ({
        ...state,
        requesting: false,
        loading: false,
        pets: currentPets,
      }));
      setRequestingCancelPet(false);
      setOpenCancelPet(false);
      refreshSubscriptions();
    } else {
      setState((state) => ({
        ...state,
        snackMessage: {
          display: true,
          message: response.message,
          type: "error",
        },
      }));

      setRequestingCancelPet(false);
      setOpenCancelPet(false);
    }
  };

  async function getProfile() {
    setState((state) => ({
      ...state,
      requesting: true,
      error: false,
    }));
    const token = authContext.auth.authItem.accessToken;
    const id = authContext.auth.authItem.decodedJwt.sub;
    const response = await fetchProfile(token, id);

    if (response.success) {
      const addresses = [...(response.client.addresses ?? [])];
      addresses.forEach((address) => {
        address.stateAddress = address.state;
      });

      setCheckoutState((checkoutState) => ({
        ...checkoutState,
        addresses: addresses,
        prices: response.data.subscriptionCosts,
        client: response.client,
      }));

      const clearedSubs = response.data.subscriptions.map((sub) => {
        const transactionIds = sub.detail.map((detail) => {
          return detail.transactionId;
        });

        const transactionIdSet = new Set(transactionIds);
        const uniqueTransactionIdSet = [...transactionIdSet];
        const processedDetails = [];
        uniqueTransactionIdSet.forEach((transactionId) => {
          const details = sub.detail.filter(
            (detail) => detail.transactionId === transactionId
          );

          // =====================================================
          const subscriptionItem = details.filter(
            (detail) => detail.type === "new" || detail.type === "renew"
          );
          const hasSubscription = subscriptionItem.length > 0;
          const subscriptionType =
            subscriptionItem.length > 0
              ? subscriptionItem[0].clientId !== null
                ? "Business Card"
                : "Pet Card"
              : "";
          const subscriptionTitle =
            subscriptionItem.length > 0
              ? `Subscription (${subscriptionType}) - $${subscriptionItem[0].amount}`
              : "";
          // =====================================================

          // =====================================================
          const additionalPets = details.filter(
            (detail) => detail.type === "additional" && detail.clientId === null
          );
          let additionalPetsPrice = 0;
          let additionalPetsCounter = 0;
          additionalPets.forEach((item) => {
            additionalPetsPrice += item.amount;
            additionalPetsCounter++;
          });
          const additionalPetsTitle =
            additionalPetsCounter > 0
              ? `Additional (Pet Card) x${additionalPetsCounter} - $${additionalPetsPrice}`
              : "";
          // =====================================================

          // =====================================================
          const additionalBusiness = details.filter(
            (detail) => detail.type === "additional" && detail.clientId !== null
          );
          let additionalBusinessPrice = 0;
          let additionalBusinessCounter = 0;
          additionalBusiness.forEach((item) => {
            additionalBusinessPrice += item.amount;
            additionalBusinessCounter++;
          });
          const additionalBusinessTitle =
            additionalBusinessCounter > 0
              ? `Additional (Business Card) x${additionalBusinessCounter} - $${additionalBusinessPrice}`
              : "";
          // =====================================================

          const titlesArray = [
            subscriptionTitle,
            additionalPetsTitle,
            additionalBusinessTitle,
          ];
          const validTitles = titlesArray.filter((title) => title.length > 0);

          let unpaidTitle = validTitles.join(", ");
          processedDetails.push({
            title: unpaidTitle,
            details,
            unpaid: transactionId !== null,
            hasSubscription,
          });
        });
        return {
          ...sub,
          processedDetails,
        };
      });
      setState((state) => ({
        ...state,
        requesting: false,
        loading: false,
        error: false,
        data: response.data,
        client: response.client,
        pets: response.pets ?? [],
        subscriptions: clearedSubs,
        mediaTypes: response.mediaTypes,
        hasBuiness: response.data?.subscriptions[0]?.hasBusinessCard ?? true,
        blockAll: response.data?.subscriptions[0]?.isExpired,
      }));
    } else {
      setState((state) => ({
        ...state,
        requesting: false,
        loading: false,
        error: true,
        message: response.message,
      }));
    }
  }

  useEffect(() => {
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshSubscriptions = async () => {
    setState((state) => ({
      ...state,
      requesting: true,
      loading: true,
      error: false,
    }));
    const token = authContext.auth.authItem.accessToken;
    const id = authContext.auth.authItem.decodedJwt.sub;
    const response = await refreshSubscription(token, id);

    if (response.success) {
      const clearedSubs = response.subscriptions.map((sub) => {
        const transactionIds = sub.detail.map((detail) => {
          return detail.transactionId;
        });

        const transactionIdSet = new Set(transactionIds);
        const uniqueTransactionIdSet = [...transactionIdSet];
        const processedDetails = [];
        uniqueTransactionIdSet.forEach((transactionId) => {
          const details = sub.detail.filter(
            (detail) => detail.transactionId === transactionId
          );

          // =====================================================
          const subscriptionItem = details.filter(
            (detail) => detail.type === "new"
          );
          const subscriptionType =
            subscriptionItem.length > 0
              ? subscriptionItem[0].clientId !== null
                ? "Business Card"
                : "Pet Card"
              : "";
          const subscriptionTitle =
            subscriptionItem.length > 0
              ? `Subscription (${subscriptionType}) - $${subscriptionItem[0].amount}`
              : "";
          // =====================================================

          // =====================================================
          const additionalPets = details.filter(
            (detail) => detail.type === "additional" && detail.clientId === null
          );
          let additionalPetsPrice = 0;
          let additionalPetsCounter = 0;
          additionalPets.forEach((item) => {
            additionalPetsPrice += item.amount;
            additionalPetsCounter++;
          });
          const additionalPetsTitle =
            additionalPetsCounter > 0
              ? `Additional (Pet Card) x${additionalPetsCounter} - $${additionalPetsPrice}`
              : "";
          // =====================================================

          // =====================================================
          const additionalBusiness = details.filter(
            (detail) => detail.type === "additional" && detail.clientId !== null
          );
          let additionalBusinessPrice = 0;
          let additionalBusinessCounter = 0;
          additionalBusiness.forEach((item) => {
            additionalBusinessPrice += item.amount;
            additionalBusinessCounter++;
          });
          const additionalBusinessTitle =
            additionalBusinessCounter > 0
              ? `Additional (Business Card) x${additionalBusinessCounter} - $${additionalBusinessPrice}`
              : "";
          // =====================================================

          const titlesArray = [
            subscriptionTitle,
            additionalPetsTitle,
            additionalBusinessTitle,
          ];
          const validTitles = titlesArray.filter((title) => title.length > 0);

          let unpaidTitle = validTitles.join(", ");
          processedDetails.push({
            title: unpaidTitle,
            details,
            unpaid: transactionId !== null,
          });
        });
        return {
          ...sub,
          processedDetails,
        };
      });

      setState((state) => ({
        ...state,
        requesting: false,
        loading: false,
        error: false,
        subscriptions: clearedSubs,
        hasBuiness: response.subscriptions[0]?.hasBusinessCard,
      }));
    } else {
      setState((state) => ({
        ...state,
        requesting: false,
        loading: false,
        error: true,
        message: response.message,
      }));
    }
  };

  const initializeErrors = (itemState) => {
    const errors = { ...initialErrors };
    const keys = Object.keys(errors);
    for (let index = 0; index < state.pets.length; index++) {
      errors.pets.push("");
    }

    keys.forEach((key) => {
      if (Array.isArray(itemState[key]) && !key.includes("Section")) {
        if (key === "phoneList" || key === "emailList") {
          errors[key] = [...initialErrors[key]];
          itemState[key].forEach((element, index) => {
            errors[key].push({ value: "", type: "" });
          });
        }
        if (key === "mediaList") {
          errors[key] = [...initialErrors[key]];
          itemState[key].forEach((element, index) => {
            errors[key].push({ value: "", type: "", url: "" });
          });
        }
        if (key === "addresses") {
          errors[key] = [...initialErrors[key]];
          itemState[key].forEach((element, index) => {
            errors[key].push({
              address1: "",
              address2: "",
              city: "",
              state: "",
              zip: "",
            });
          });
        }
      } else {
      }
      setState((state) => ({
        ...state,
        errors,
      }));
    });
  };

  const onEditClick = () => {
    const editing = !state.editing;
    const mutableClient = structuredClone(state.client);
    const mutablePets = structuredClone(state.pets);
    const clientEdit = editing ? { ...mutableClient } : {};
    const petsEdit = editing ? [...mutablePets] : [];
    setState((state) => ({
      ...state,
      editing,
      clientEdit,
      petsEdit,
    }));
    initializeErrors(clientEdit);
  };

  const validateClientForm = () => {
    let valid = true;
    const errors = { ...initialErrors };
    const clientEdit = { ...state.clientEdit };

    const emptyFirstName = clientEdit.firstName.length === 0;
    const validFirstName = singleNameRegex.test(clientEdit.firstName);
    if (emptyFirstName || !validFirstName) {
      valid = false;
      if (emptyFirstName) {
        errors.firstName = "Required Input";
      }
      if (!validFirstName) {
        errors.firstName = "Invalid Input";
      }
    }

    const emptyLastName = clientEdit.lastName.length === 0;
    const validLastName = singleNameRegex.test(clientEdit.lastName);
    if (emptyLastName || !validLastName) {
      valid = false;
      if (emptyLastName) {
        errors.lastName = "Required Input";
      }
      if (!validLastName) {
        errors.lastName = "Invalid Input";
      }
    }

    const emptyPhone = clientEdit.phone.length === 0;
    const validPhone = matchIsValidTel(clientEdit.phone);
    if (emptyPhone || !validPhone) {
      valid = false;
      if (emptyPhone) {
        errors.phone = "Required Input";
      }
      if (!validPhone) {
        errors.phone = "Invalid Input";
      }
    }

    const emptyEmail = clientEdit.email.length === 0;
    const validEmail = emailRegex.test(clientEdit.email);
    if (emptyEmail || !validEmail) {
      valid = false;
      if (emptyEmail) {
        errors.email = "Required Input";
      }
      if (!validEmail) {
        errors.email = "Invalid Input";
      }
    }

    errors.phoneList = clientEdit.phoneList.map((element) => {
      const emptyType = element.type.length === 0;
      const emptyValue = element.value.length === 0;
      const validValue = matchIsValidTel(element.value);
      let errorValue = "";
      let errorType = "";

      if (emptyType || emptyValue || !validValue) {
        valid = false;
        if (emptyType) {
          errorType = "Required Input";
        }
        if (emptyValue) {
          errorValue = "Required Input";
        }
        if (!validValue) {
          errorValue = "Invalid Input";
        }
      }
      return { value: errorValue, type: errorType };
    });

    errors.emailList = clientEdit.emailList.map((element) => {
      const emptyType = element.type.length === 0;
      const emptyValue = element.value.length === 0;
      const validValue = emailRegex.test(element.value);
      let errorValue = "";
      let errorType = "";

      if (emptyType || emptyValue || !validValue) {
        valid = false;
        if (emptyType) {
          errorType = "Required Input";
        }
        if (emptyValue) {
          errorValue = "Required Input";
        }
        if (!validValue) {
          errorValue = "Invalid Input";
        }
      }
      return { value: errorValue, type: errorType };
    });

    errors.mediaList = clientEdit.mediaList.map((element) => {
      const emptyType = element.type?.length === 0;
      const emptyValue = element.value.length === 0;
      let errorValue = "";
      let errorType = "";
      let errorUrl = "";

      if (emptyType || emptyValue) {
        valid = false;
        if (emptyType) {
          errorType = "Required Input";
        }
        if (emptyValue) {
          errorValue = "Required Input";
        }
      }
      return { value: errorValue, type: errorType, url: errorUrl };
    });

    errors.addresses = clientEdit.addresses.map((element) => {
      const address1Empty = element.address1.length === 0;
      const cityEmpty = element.city.length === 0;
      const stateEmpty = element.state.length === 0;
      const zipEmpty = element.zip.length === 0;
      let address1 = "";
      let address2 = "";
      let city = "";
      let state = "";
      let zip = "";

      if (address1Empty || cityEmpty || stateEmpty || zipEmpty) {
        valid = false;
        if (element.address1.length === 0) {
          address1 = "Required Input";
        }
        if (element.city.length === 0) {
          city = "Required Input";
        }
        if (element.state.length === 0) {
          state = "Required Input";
        }
        if (element.zip.length === 0) {
          zip = "Required Input";
        }
      }
      return { address1, address2, city, state, zip };
    });

    setState((state) => ({
      ...state,
      errors,
    }));
    return valid;
  };

  const updateClient = async () => {
    const validForm = validateClientForm();
    if (!validForm) {
      return;
    }
    setState((state) => ({
      ...state,
      requesting: true,
    }));
    try {
      const mutableClient = { ...state.clientEdit };
      delete mutableClient.img64;
      const mainPhone = removeWhiteSpaces(mutableClient.phone);
      mutableClient.phone = mainPhone;
      if (mutableClient.phoneList.length === 0) {
        mutableClient.phoneList = null;
      } else {
        const rawPhones = [...mutableClient.phoneList];
        mutableClient.phoneList = rawPhones.map((element) => {
          return {
            ...element,
            value: removeWhiteSpaces(element.value),
          };
        });
      }
      if (mutableClient.emailList.length === 0) {
        mutableClient.emailList = null;
      }
      if (mutableClient.mediaList.length === 0) {
        mutableClient.mediaList = null;
      }
      const updateClientConfig = {
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + authContext.auth.authItem.accessToken,
        },
        body: JSON.stringify(mutableClient),
      };

      const id = authContext.auth.authItem.decodedJwt.sub;
      const response = await fetch(
        `${baseUrl}/clients/${id}`,
        updateClientConfig
      );
      const responseData = await response.json();
      if (response.ok) {
        const mutableClient = { ...responseData };
        if (mutableClient.phoneList === null) {
          mutableClient.phoneList = [];
        }
        if (mutableClient.emailList === null) {
          mutableClient.emailList = [];
        }
        if (mutableClient.mediaList === null) {
          mutableClient.mediaList = [];
        }
        setState((state) => ({
          ...state,
          client: { ...state.client, ...mutableClient },
          editing: false,
          requesting: false,
          snackMessage: {
            display: true,
            message: "Your information was successfully updated",
            type: "success",
          },
        }));
      } else {
        setState((state) => ({
          ...state,
          requesting: false,
          snackMessage: {
            display: true,
            message: responseData?.message ?? "Could not update the profile",
            type: "error",
          },
        }));
      }
    } catch (error) {
      console.error(error);
      setState((state) => ({
        ...state,
        requesting: false,
        error: true,
        message: JSON.stringify(error.message),
      }));
    }
  };

  const validatePetsForm = () => {
    let valid = true;
    const errors = structuredClone(initialErrors);
    for (let index = 0; index < state.pets.length; index++) {
      errors.pets.push("");
    }
    const validPets = state.petsEdit.some((pet) => pet.length === 0);

    if (validPets) {
      valid = false;
    }
    for (let index = 0; index < state.petsEdit.length; index++) {
      if (!singleNameRegex.test(state.petsEdit[index].name)) {
        errors.pets[index] = "Invalid field";
        valid = false;
      }
      if (state.petsEdit[index].name.length === 0) {
        errors.pets[index] = "Required field";
        valid = false;
      }
    }
    setState((state) => ({
      ...state,
      errors,
    }));
    return valid;
  };

  const updatePets = async () => {
    const validForm = validatePetsForm();
    if (!validForm) {
      return;
    }
    setState((state) => ({
      ...state,
      requesting: true,
    }));
    try {
      const mutablePets = structuredClone(state.petsEdit);
      const originalPets = structuredClone(state.pets);

      const editedPets = mutablePets.filter(
        (pet, index) => pet.name !== state.pets[index].name
      );

      const token = authContext.auth.authItem.accessToken;
      const responses = await updateAllPets(editedPets, token);
      responses.forEach((response) => {
        if (response.success) {
          const index = originalPets.findIndex(
            (pet) => pet.petId === response.responseData?.petId
          );
          if (index !== -1) {
            originalPets[index].name = response.responseData?.name;
          }
        }
      });

      const failed = responses.some((response) => response.success === false);
      if (!failed) {
        setState((state) => ({
          ...state,
          pets: originalPets,
          editing: false,
          requesting: false,
          snackMessage: {
            display: true,
            message: "Your information was successfully updated",
            type: "success",
          },
        }));
      } else {
        setState((state) => ({
          ...state,
          pets: originalPets,
          editing: false,
          requesting: false,
          snackMessage: {
            display: true,
            message:
              "There was a problem updating the pets info, please try again",
            type: "error",
          },
        }));
      }
    } catch (error) {
      console.error(error);
      setState((state) => ({
        ...state,
        requesting: false,
        error: true,
        message: JSON.stringify(error.message),
      }));
    }
  };

  const updateFunction = () => {
    if (tab === 1) {
      updateClient();
    } else {
      if (tab === 2) {
        updatePets();
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState((state) => ({
      ...state,
      snackMessage: {
        display: false,
        message: "",
        type: "info",
      },
    }));
  };

  const handleCloseBusiness = () => {
    setOpenNewBusiness(false);
  };

  const handleCloseCancelPet = () => {
    setOpenCancelPet(false);
  };

  const fileInputRef = useRef();
  const [files, setFiles] = useState(null);
  useEffect(() => {
    if (files) {
      const updateProfilePicture = async () => {
        const token = authContext.auth.authItem.accessToken;
        const image = files[0];
        const id = authContext.auth.authItem.decodedJwt.sub;
        const response = await updateImage(token, id, image, "client");
        if (response.success) {
          const client = { ...state.client };
          client.image = response.responseData.image;
          client.img64 = response.responseData.img64;
          setState((state) => ({
            ...state,
            client,
            clientEdit: client,
            requesting: false,
            snackMessage: {
              display: true,
              message: "Your profile picture was successfully updated",
              type: "success",
            },
          }));
          setFiles(null);
        } else {
          setState((state) => ({
            ...state,
            requesting: false,
            snackMessage: {
              display: true,
              message:
                response?.message ?? "Could not update the profile image",
              type: "error",
            },
          }));
          setFiles(null);
        }
      };
      updateProfilePicture();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const [tab, setTab] = useState(0);

  const handleAddBusiness = async () => {
    if (state.requesting) {
      return;
    }
    setState((state) => ({
      ...state,
      requesting: true,
    }));
    setRequestingBusiness(true);
    try {
      const token = authContext.auth.authItem.accessToken;
      const subscriptionId = state.subscriptions[0]?.subscriptionId;
      const response = await additionalBusinessAdd(subscriptionId, token);
      await refreshSubscriptions();
      if (response.success) {
        setOpenNewBusiness(false);
        setRequestingBusiness(false);
        setState((state) => ({
          ...state,
          requesting: false,
          loading: false,
          snackMessage: {
            display: true,
            message: "Your information was successfully updated",
            type: "success",
          },
        }));
      } else {
        setOpenNewBusiness(false);
        setRequestingBusiness(false);
        setState((state) => ({
          ...state,
          requesting: false,
          loading: false,
          snackMessage: {
            display: true,
            message:
              "There was a problem updating the pets info, please try again",
            type: "error",
          },
        }));
      }
    } catch (error) {
      setRequestingBusiness(false);
      console.error(error);
      setState((state) => ({
        ...state,
        requesting: false,
        loading: false,
        error: true,
        message: JSON.stringify(error.message),
      }));
    }
  };

  const handleDeleteBusiness = async () => {
    if (state.requesting) {
      return;
    }
    setState((state) => ({
      ...state,
      requesting: true,
    }));
    setRequestingBusiness(true);

    try {
      const token = authContext.auth.authItem.accessToken;
      const subscriptionId = state.subscriptions[0]?.subscriptionId;
      const response = await additionalBusinessDelete(subscriptionId, token);
      await refreshSubscriptions();
      if (response.success) {
        setOpenCancelBusiness(false);
        setState((state) => ({
          ...state,
          requesting: false,
          loading: false,
          snackMessage: {
            display: true,
            message: "Your information was successfully updated",
            type: "success",
          },
        }));
      } else {
        setOpenCancelBusiness(false);
        setState((state) => ({
          ...state,
          requesting: false,
          loading: false,
          snackMessage: {
            display: true,
            message:
              "There was a problem updating the pets info, please try again",
            type: "error",
          },
        }));
      }
    } catch (error) {
      setRequestingBusiness(false);
      console.error(error);
      setState((state) => ({
        ...state,
        requesting: false,
        loading: false,
        error: true,
        message: JSON.stringify(error.message),
      }));
    }
  };

  const handleCloseCancelBusiness = () => {
    setOpenCancelBusiness(false);
  };

  return (
    <ProfileContext.Provider
      value={{
        profileState: state,
        checkoutState,
        setCheckoutState,
        initialCheckoutState,
        isLoaded,
        onloads,
        refs,
        onPlaceChanged,
      }}
    >
      <ThemeProvider theme={defaultTheme}>
        {state.loading && <Loader />}
        {!state.loading && (
          <>
            {state.error && <ErrorComponent message={state.message} />}
            <Snackbar open={state.snackMessage.display} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity={state.snackMessage.type}
                sx={{ width: "100%" }}
              >
                {state.snackMessage.message}
              </Alert>
            </Snackbar>
            {!state.error && (
              <>
                <ProfileHeaderSection />
                {state.editing && (
                  <FloatingActions
                    update={updateFunction}
                    onEditClick={onEditClick}
                    updating={state.requesting}
                  />
                )}
                <div className="bg-gradient-profile" />
                <Box sx={{ mt: -7, px: { sx: 1, md: 2, lg: 4 }, mb: 5 }}>
                  {!state.editing && (
                    <ClientDisplay
                      state={state}
                      setState={setState}
                      setFiles={setFiles}
                      onEditClick={onEditClick}
                      fileInputRef={fileInputRef}
                      tab={tab}
                      setTab={setTab}
                      setOpenNewPet={setOpenNewPet}
                      setOpenNewBusiness={setOpenNewBusiness}
                      setOpenCancelPet={setOpenCancelPet}
                      setPetIndexCancel={setPetIndexCancel}
                      setOpenCancelBusiness={setOpenCancelBusiness}
                      refreshSubscriptions={refreshSubscriptions}
                    />
                  )}
                  {state.editing && (
                    <ClientForm
                      state={state}
                      setFiles={setFiles}
                      setState={setState}
                      fileInputRef={fileInputRef}
                      initializeErrors={initializeErrors}
                      tab={tab}
                    />
                  )}
                  {openNewPet && (
                    <AddPetForm
                      state={state}
                      setState={setState}
                      openNewPet={openNewPet}
                      setOpenNewPet={setOpenNewPet}
                      refreshSubscriptions={refreshSubscriptions}
                    />
                  )}
                  {openNewBusiness && (
                    <Dialog
                      open={openNewBusiness}
                      keepMounted
                      onClose={handleCloseBusiness}
                      aria-describedby="alert-dialog-slide-description"
                    >
                      <DialogContent>
                        <Typography
                          variant="h5"
                          component="h3"
                          color="#33428C"
                          sx={{
                            fontWeight: "bold",
                            textAlign: "center",
                            mt: 1,
                          }}
                        >
                          Would you like to add a Business Card?
                        </Typography>
                      </DialogContent>
                      <DialogActions sx={{ justifyContent: "center", mb: 2 }}>
                        <Button
                          variant="outlined"
                          color="primaryTk"
                          sx={{ borderRadius: "30px" }}
                          onClick={handleCloseBusiness}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primaryTk"
                          sx={{ ml: 1, borderRadius: "30px" }}
                          onClick={handleAddBusiness}
                        >
                          {requestingBusiness && (
                            <CircularProgress color="white" size="1.4rem" />
                          )}
                          {!requestingBusiness && <>Accept</>}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  )}
                  {openCancelPet && (
                    <Dialog
                      open={openCancelPet}
                      keepMounted
                      onClose={handleCloseBusiness}
                      aria-describedby="alert-dialog-slide-description"
                    >
                      <DialogContent>
                        <Typography
                          variant="h5"
                          component="h3"
                          color="#33428C"
                          sx={{
                            fontWeight: "bold",
                            textAlign: "center",
                            mt: 1,
                          }}
                        >
                          Would you like to cancel this pet?
                        </Typography>
                      </DialogContent>
                      <DialogActions sx={{ justifyContent: "center", mb: 2 }}>
                        <Button
                          variant="outlined"
                          color="primaryTk"
                          sx={{ borderRadius: "30px" }}
                          onClick={handleCloseCancelPet}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primaryTk"
                          sx={{ ml: 1, borderRadius: "30px" }}
                          onClick={() => handleDeletePet(petIndexCancel)}
                        >
                          {requestingCancelPet && (
                            <CircularProgress color="white" size="1.4rem" />
                          )}
                          {!requestingCancelPet && <>Accept</>}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  )}

                  {openCancelBusiness && (
                    <Dialog
                      open={openCancelBusiness}
                      keepMounted
                      onClose={handleCloseBusiness}
                      aria-describedby="alert-dialog-slide-description"
                    >
                      <DialogContent>
                        <Typography
                          variant="h5"
                          component="h3"
                          color="#33428C"
                          sx={{
                            fontWeight: "bold",
                            textAlign: "center",
                            mt: 1,
                          }}
                        >
                          Would you like to cancel this Business Card?
                        </Typography>
                      </DialogContent>
                      <DialogActions sx={{ justifyContent: "center", mb: 2 }}>
                        <Button
                          variant="outlined"
                          color="primaryTk"
                          sx={{ borderRadius: "30px" }}
                          onClick={handleCloseCancelBusiness}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primaryTk"
                          sx={{ ml: 1, borderRadius: "30px" }}
                          onClick={handleDeleteBusiness}
                        >
                          {state.requesting && (
                            <CircularProgress color="white" size="1.4rem" />
                          )}
                          {!state.requesting && <>Accept</>}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  )}
                </Box>
              </>
            )}
          </>
        )}
      </ThemeProvider>
    </ProfileContext.Provider>
  );
};
export default React.memo(Profile);

const ErrorComponent = ({ message }) => {
  return (
    <Container component="main" maxWidth="md" sx={{ pt: 2 }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={logo} width="17%" alt="TapKards Logo" />
        <Typography
          component="h1"
          variant="h4"
          textAlign="center"
          data-cy="errorTitle"
          sx={{ mt: 2 }}
        >
          Whoops! Seems like there <br /> was an error getting your info
        </Typography>
        <Alert data-cy="errorAlert" severity="error" sx={{ mt: 3 }}>
          {message}
        </Alert>
      </Box>
    </Container>
  );
};
