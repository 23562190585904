import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import logo from "../../image/logo.png";
import { Link } from "react-router-dom";
import { Backdrop, Stack } from "@mui/material";
import { AuthContext } from "../../App";

const defaultTheme = createTheme({
  palette: {
    primaryLb: {
      main: "#c2608e",
      light: "#ce7fa4",
      dark: "#874363",
      contrastText: "#fff",
    },
  },
});

export default function NoMatch() {
  const authContext = useContext(AuthContext);
  return (
    <ThemeProvider theme={defaultTheme}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
        className="footer theme-bg loader"
      >
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }}
        >
          <Box sx={{ maxWidth: { xs: 0.7, md: "0.4", lg: "0.2" } }}>
            <img src={logo} className="img-fluid" alt="tapkards logo" />
          </Box>
          <Typography
            component="h1"
            variant="h3"
            data-cy="errorTitle"
            sx={{ mt: 2, textAlign: "center" }}
          >
            Sorry!
          </Typography>
          <Typography
            component="h1"
            variant="h4"
            data-cy="errorTitle"
            sx={{ mt: 2, textAlign: "center" }}
          >
            Oops, looks like we couldn't <br /> find what you're looking for.
          </Typography>
          <Link to="/profile" className="text-light">
            Go to the {authContext.auth.loggedIn && <>profile</>}
            {!authContext.auth.loggedIn && <>login</>} page
          </Link>
        </Stack>
      </Backdrop>
    </ThemeProvider>
  );
}
