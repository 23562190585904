import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../App";
import "react-multi-carousel/lib/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import heroBanner from "../../images/slider-img/hero-banner.png";
import downArrow from "../../images/down-arrow.png";
import howWorks from "../../images/works/how-works.jpg";
import carousel01 from "../../image/carousel-01.png";
import carousel02 from "../../image/carousel-02.png";
import carousel03 from "../../image/carousel-03.png";
import carousel04 from "../../image/carousel-04.png";
import carousel05 from "../../image/carousel-05.png";
import carousel06 from "../../image/carousel-06.png";
import carousel07 from "../../image/carousel-07.png";
import carousel08 from "../../image/carousel-08.png";
import carousel09 from "../../image/carousel-09.png";
import carousel10 from "../../image/carousel-10.png";
import carousel11 from "../../image/carousel-11.png";
import carousel12 from "../../image/carousel-12.png";
import cardsicon from "../../image/cards-icon.png";
import about01 from "../../images/aboutus/about01.jpg";
import about02 from "../../images/aboutus/about02.jpg";
import WOW from "wowjs";
import Carousel from "react-multi-carousel";
import Typed from "react-typed";
import CheckIcon from "@mui/icons-material/Check";
import Stack from "@mui/material/Stack";
import PetsIcon from "@mui/icons-material/Pets";
import "../../css/materialdesignicons.min.css";
import "../../css/animate.css";
import "../../css/style.css";
import "./style.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LandingNavBar from "./components/LandingNavbar";
import LandingFooter from "./components/LandingFooter";
import { fetchPrices } from "../../config/api";
import Skeleton from "@mui/material/Skeleton";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";

const LandingPage = () => {
  const authContext = useContext(AuthContext);

  const [state, setState] = useState({
    loading: true,
    status: "loading",
    message: "",
    landing: {},
  });

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
    const configLanding = async () => {
      const config = await fetchPrices();
      if (config.success) {
        authContext.setAuth({
          ...authContext.auth,
          landing: config,
        });
        setState((state) => ({
          ...state,
          status: config.success,
          loading: false,
          landing: config,
        }));
      } else {
        setState((state) => ({
          ...state,
          status: config.success,
          loading: false,
        }));
      }
    };
    configLanding();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <LandingNavBar />
      <section className="home-section" id="home">
        <div className="lines">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>

        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <div className="home-bg">
          <div className="welcome-text-area">
            <div className="area-bg"></div>
            <div className="welcome-area">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="welcome-text text-center">
                      <Typography
                        variant="h4"
                        align="center"
                        component="h1"
                        color="white"
                      >
                        Turn More Interactions Into Sales
                      </Typography>
                      <Typography
                        variant="h4"
                        align="center"
                        component="h2"
                        color="white"
                        sx={{ fontWeight: "light" }}
                      >
                        &nbsp;
                        <Typed
                          strings={[
                            "Leave paper behind",
                            "Protect the Planet",
                            "Instantly share your contact",
                            "Convenient to carry",
                          ]}
                          typeSpeed={30}
                          loop
                        />
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div
                      className="welcome-img d-flex justify-content-center align-items-center wow fadeInUp"
                      data-wow-delay="1s"
                      data-wow-duration="1.5s"
                    >
                      <img src={heroBanner} className="img-fluid" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a href="#trustedby" className="scroll-next">
          <img src={downArrow} className="img-fluid" alt="" />
        </a>
      </section>

      <section className="section" id="trustedby">
        <div className="container">
          <div className="title-block mx-auto">
            <div className="section_title mb-4 text-center">
              <h2>
                <span>Trusted By</span>
              </h2>
              <span
                className="scroll-title"
                data-enllax-ratio="2.5"
                data-enllax-type="foreground"
                data-enllax-direction="horizontal"
              >
                Trusted By
              </span>
              <Carousel
                additionalTransfrom={0}
                arrows={false}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                itemClass="my-auto px-3"
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024,
                    },
                    items: 5,
                    partialVisibilityGutter: 40,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 2,
                    partialVisibilityGutter: 30,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 3,
                    partialVisibilityGutter: 30,
                  },
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                <a href="https://mbusa.com">
                  <img
                    src={carousel01}
                    className="img-fluid my-auto  px-1"
                    alt=""
                  />
                </a>
                <a href="https://automobiles.honda.com">
                  <img
                    src={carousel02}
                    className="img-fluid my-auto  px-3"
                    alt=""
                  />
                </a>
                <a href="https://allstate.com">
                  <img src={carousel03} className="img-fluid my-auto" alt="" />
                </a>
                <a href="https://labcorp.com">
                  <img src={carousel04} className="img-fluid my-auto" alt="" />
                </a>
                <a href="https://mcdonalds.com">
                  <img
                    src={carousel05}
                    className="img-fluid my-auto px-5"
                    alt=""
                  />
                </a>
                <a href="https://century21.com">
                  <img src={carousel06} className="img-fluid my-auto" alt="" />
                </a>
                <a href="https://bluestarhealth.org">
                  <img src={carousel07} className="img-fluid my-auto" alt="" />
                </a>
                <a href="https://epiclot.com">
                  <img
                    src={carousel08}
                    className="img-fluid my-auto px-2"
                    alt=""
                  />
                </a>
                <a href="https://remotem.io">
                  <img
                    src={carousel09}
                    className="img-fluid my-auto px-4"
                    alt=""
                  />
                </a>
                <a href="https://vitalplasma.com">
                  <img src={carousel10} className="img-fluid my-auto" alt="" />
                </a>
                <a href="https://wellviewcare.org">
                  <img src={carousel11} className="img-fluid my-auto" alt="" />
                </a>
                <a href="https://yesipex.com">
                  <img src={carousel12} className="img-fluid my-auto" alt="" />
                </a>
              </Carousel>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 col-sm-12">
              <div
                className="about-image-block wow fadeInUp"
                data-wow-delay="0.2s"
                data-wow-duration="1.5s"
              >
                <img src={about01} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div
                className="about-text-block wow fadeInUp"
                data-wow-delay="0.4s"
                data-wow-duration="1.5s"
              >
                <div className="about-content">
                  <h4>
                    REDEFINING NETWORKING <br />
                    EXCELLENCE
                  </h4>
                  <div className="text">
                    For unforgettable connections with a simple tap of your
                    TapKard, instantly sharing your digital business card with
                    their phone. Easily customize your TapKard profile to fit
                    any scenario.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 col-sm-12 order-md-2">
              <div
                className="about-image-block wow fadeInUp"
                data-wow-delay="0.4s"
                data-wow-duration="1.5s"
              >
                <img src={about02} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-6 col-sm-12 order-md-1">
              <div
                className="about-text-block wow fadeInUp"
                data-wow-delay="0.2s"
                data-wow-duration="1.5s"
              >
                <div className="about-content">
                  <h4>
                    CONVENIENT TO <br />
                    CARRY
                  </h4>
                  <div className="text">
                    Leave paper behind and move to the modern day with TapKard
                    business cards; they always look as good as the first day
                    you got them. With no limit on how many times you can use
                    your card, you'll never run out of business cards when you
                    need them most.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section" id="howtoget">
        <div className="container">
          <div className="title-block mx-auto">
            <div className="section_title mb-4 text-center">
              <h2>
                <span>How To Get Your Card</span>
              </h2>
              <span
                className="scroll-title"
                data-enllax-ratio="2.5"
                data-enllax-type="foreground"
                data-enllax-direction="horizontal"
              >
                How To Get Your Card
              </span>
            </div>
          </div>
          <div className="work-steps-process">
            <div className="work-steps-box">
              <div className="row align-items-center">
                <div className="col-md-6 col-sm-12">
                  <div
                    className="work-steps-detail d-flex align-items-start wow fadeInLeft"
                    data-wow-delay="0.3s"
                    data-wow-duration="1000ms"
                  >
                    <div className="steps-icon">
                      <i className="mdi mdi-numeric-1"></i>
                    </div>
                    <div className="steps-text">
                      <h4>
                        <strong>ORDER YOUR CARD</strong>
                      </h4>
                      <p className="step-desc">
                        Upon purchase, our team will send you an email to
                        complete a design brief. They will then create your card
                        for your approval at no additional charge.
                      </p>
                    </div>
                  </div>
                  <div
                    className="work-steps-detail d-flex align-items-start wow fadeInLeft"
                    data-wow-delay="0.6s"
                    data-wow-duration="1000ms"
                  >
                    <div className="steps-icon">
                      <i className="mdi mdi-numeric-2"></i>
                    </div>
                    <div className="steps-text">
                      <h4>
                        <strong>COMPLETE YOUR PROFILE</strong>
                      </h4>
                      <p className="step-desc">
                        While we manufacture your card you can create your
                        digital profile using our online platform.{" "}
                        <span className="fw-bold">
                          Link your contact details, social profiles, website
                          and more.
                        </span>
                      </p>
                    </div>
                  </div>
                  <div
                    className="work-steps-detail d-flex align-items-start wow fadeInLeft"
                    data-wow-delay="0.9s"
                    data-wow-duration="1000ms"
                  >
                    <div className="steps-icon">
                      <i className="mdi mdi-numeric-3"></i>
                    </div>
                    <div className="steps-text">
                      <h4>
                        <strong>TAP, SHARE, GO</strong>
                      </h4>
                      <p className="step-desc">
                        When you receive your card, pair it with your account
                        and step into the future of networking
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div
                    className="work-steps-images wow fadeInRight"
                    data-wow-delay="0.2"
                    data-wow-duration="1000ms"
                  >
                    <img src={howWorks} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section bg-light" id="buy">
        <div className="container">
          <div className="title-block mx-auto">
            <div className="section_title mb-4 text-center">
              <h2>
                <span>Buy Tapkard Now</span>
              </h2>
              <span
                className="scroll-title"
                data-enllax-ratio="2.5"
                data-enllax-type="foreground"
                data-enllax-direction="horizontal"
              >
                Buy Tapkard Now
              </span>
              <p className="section_subtitle mx-auto">
                Get more value with Tapkards
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="monthly">
                  <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="price-box">
                        <div className="p-box-inner">
                          <div className="hover-line">
                            <div className="price-box-header">
                              <div className="plan-icons2">
                                
                              <img
                                    src={cardsicon}
                                    className="img-fluid"
                                    alt=""
                                  />
                              </div>
                            </div>
                            <Stack
                              direction="column"
                              justifyContent="center"
                              alignItems="stretch"
                              spacing={1}
                              className="package-list-data"
                              sx={{ mx: 2, fontSize: "0.9rem", mb: 3 }}
                            >
                              <div className="package-list-item">
                                <CheckIcon className="pricing-feature-icons" />
                                NFC technology
                              </div>
                              <div className="package-list-item">
                                <CheckIcon className="pricing-feature-icons" />
                                Profile can customized on any device
                              </div>
                              <div className="package-list-item">
                                <CheckIcon className="pricing-feature-icons" />
                                Logo and name on the front
                              </div>
                              <div className="package-list-item">
                                <CheckIcon className="pricing-feature-icons" />
                                QR Code on the back
                              </div>
                            </Stack>

                            <Box>
                              <h2 className="d-flex align-items-center justify-content-center">
                                $
                                {state.loading && (
                                  <Skeleton width={70} height={50} />
                                )}
                                {!state.loading && (
                                  <>
                                    {state.landing.subscriptionCost + "/ "}
                                    <small>PER YEAR</small>{" "}
                                  </>
                                )}
                              </h2>
                            </Box>
                            <div className="price-box-header d-flex justify-content-center">
                              <div className="plan-icons mx-0 my-auto">
                                <PetsIcon
                                  sx={{
                                    zIndex: 1,
                                    width: "3.5rem",
                                    height: "3.5rem",
                                  }}
                                />
                              </div>
                              <div className="price-title my-auto pl-2">
                                Add-on
                              </div>
                            </div>
                            <h2 className="mb-1 d-flex align-items-center justify-content-center">
                              +$
                              {state.loading && (
                                <Skeleton width={70} height={50} />
                              )}
                              {!state.loading && (
                                <>
                                  {state.landing.additionalCost + "/ "}
                                  <small>PER YEAR</small>{" "}
                                </>
                              )}
                            </h2>
                            <Link
                              to="/checkout"
                              className="btn btn-sm theme-btn text-white text-uppercase send-btn mt-4 mb-5"
                            >
                              BUY
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="faq" className="section d-none">
        <div className="container">
          <div className="title-block mx-auto">
            <div className="section_title mb-4 text-center">
              <h2>
                <span>Frequently Asked</span>
              </h2>
              <span
                className="scroll-title"
                data-enllax-ratio="2.5"
                data-enllax-type="foreground"
                data-enllax-direction="horizontal"
              >
                Frequently Asked
              </span>
              <p className="section_subtitle mx-auto">
                this dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. this
                dolor sit amet.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9 mx-auto">
              <div
                id="accordion"
                className="faq-accordion-panel wow fadeInUp"
                data-wow-duration="1500ms"
              >
                <Accordion className="question-card shadow-sm ">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Question 1 Some other text</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse malesuada lacus ex, sit amet blandit leo
                      lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion className="question-card shadow-sm ">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography>Question 2 Some other text</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse malesuada lacus ex, sit amet blandit leo
                      lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion className="question-card shadow-sm ">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Question 3 Some other text</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse malesuada lacus ex, sit amet blandit leo
                      lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion className="question-card shadow-sm ">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography>Question 4 Some other text</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse malesuada lacus ex, sit amet blandit leo
                      lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section d-none" id="contact">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-2" />
            <div className="col-md-8">
              <div className="title-block mx-auto">
                <div className="section_title mb-4 text-center">
                  <h2>
                    <span>Get In Touch</span>
                  </h2>
                  <span
                    className="scroll-title"
                    data-enllax-ratio="4"
                    data-enllax-type="foreground"
                    data-enllax-direction="horizontal"
                  >
                    Get In Touch
                  </span>
                  <p className="section_subtitle mx-auto">
                    this dolor sit amet, consectetur adipisicing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    this dolor sit amet.
                  </p>
                </div>
              </div>
              <div
                className="contact_form wow fadeInUp"
                data-wow-delay="0.3s"
                data-wow-duration="1000ms"
              >
                <form>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          name="name"
                          id="name"
                          type="text"
                          className="form-control"
                          placeholder="Your name..."
                          required=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          name="email"
                          id="email"
                          type="email"
                          className="form-control"
                          placeholder="Your email..."
                          required=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <textarea
                          name="comments"
                          id="comments"
                          rows="4"
                          className="form-control"
                          placeholder="Your message..."
                          required=""
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="d-flex justify-content-center">
                        <button
                          type="submit"
                          className="btn btn-sm theme-btn text-white text-uppercase send-btn"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-2" />
          </div>
        </div>
      </section>

      <LandingFooter />
    </div>
  );
};

export default LandingPage;
