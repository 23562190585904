import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function BusinessCardIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
        <g id="Icon">
          <path d="M10.37,13.91A1.36,1.36,0,1,0,9,12.55,1.37,1.37,0,0,0,10.37,13.91Z"></path>
          <path d="M10.41,14.8A2.32,2.32,0,0,0,8.1,17.09v1.36h4.62V17.09A2.32,2.32,0,0,0,10.41,14.8Z"></path>
          <path d="M24.57,7.28H5.42A1.44,1.44,0,0,0,4,8.71V21.29a1.44,1.44,0,0,0,1.44,1.43H24.57A1.45,1.45,0,0,0,26,21.29V8.71A1.4,1.4,0,0,0,24.57,7.28ZM13.63,18.91a.48.48,0,0,1-.47.47H7.63a.54.54,0,0,1-.51-.47V17.09a3.22,3.22,0,0,1,1.74-2.82,2.27,2.27,0,0,1,1.51-4,2.31,2.31,0,0,1,2.32,2.28,2.22,2.22,0,0,1-.8,1.72,3.16,3.16,0,0,1,1.74,2.82Zm9.21-1.32H15.47a.47.47,0,0,1-.47-.46.48.48,0,0,1,.47-.47h7.37a.48.48,0,0,1,.47.47A.47.47,0,0,1,22.84,17.59Zm0-2.75H15.47a.47.47,0,0,1-.47-.46.48.48,0,0,1,.47-.47h7.37a.51.51,0,0,1,.47.47A.47.47,0,0,1,22.84,14.84Z"></path>
        </g>
      </svg>
    </SvgIcon>
  );
}

export default BusinessCardIcon;
