import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import logo from "../../../image/logo.png";
import "../../../css/materialdesignicons.min.css";
import "../../../css/animate.css";
import "../../../css/style.css";
import "../style.css";

const LandingNavBar = () => {
  return (
    <nav className="navbar navbar-expand-lg fixed-top custom-nav sticky ">
      <div className="container">
        <a className="navbar-brand brand-logo mr-4" href="/">
          <img src={logo} className="img-fluid logo-light" alt="" />
          <img src={logo} className="img-fluid logo-dark" alt="" />
        </a>
        <div
          className="navbar-collapse collapse justify-content-center"
          id="navbarCollapse"
        >
          <ul
            className="navbar-nav onepage-scroll navbar-center"
            id="mySidenav"
          >
            <li className="nav-item">
              <a href={`${window.location.origin}/`} className="nav-link">
                Home.
              </a>
            </li>
            <li className="nav-item">
              <a href={`${window.location.origin}/#howtoget`} className="nav-link">
                How To Get.
              </a>
            </li>
            <li className="nav-item">
              <a href={`${window.location.origin}/#buy`} className="nav-link">
                Buy Now.
              </a>
            </li>
          </ul>
          <div className="menu-close-btn">
            <i className="mdi mdi-close-circle-outline"></i>
          </div>
        </div>
        <div className="header_btn">
          <a href="/login" className="btn btn-sm white-btn">
            SIGN IN <i className="mdi mdi-arrow-right ml-2"></i>
          </a>
          <button
            className="navbar-toggler ml-2 p-0"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="mdi mdi-menu"></i>
          </button>
        </div>
      </div>
    </nav>
  );
};

export default LandingNavBar;
