import React from "react";
import PublicIcon from "@mui/icons-material/Public";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { Box, Stack } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";

const instagram = "M22.46 6c-.77.35-1.6.58-2.46.69.88-.53 1.56-1.37 1.88-2.38-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29 0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15 0 1.49.75 2.81 1.91 3.56-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07 4.28 4.28 0 0 0 4 2.98 8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21 16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56.84-.6 1.56-1.36 2.14-2.23z";
const SocialSection = ({ website, mediaList, loading }) => {
  return (
    <Box>
      {(loading || mediaList.length > 0) && (
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <PublicIcon className="main-icon-color" />
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
          sx={{ pt: 0.5 }}
        >
          {!loading && (
            <>
              {mediaList.map((media) => {
                return (
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={0.5}
                  >
                    <CustomIcon className="secondary-icon-color" iconSvg={media.svg ?? instagram} />
                    <Typography variant="body1" align="left" component="h3">
                      {media.value}
                    </Typography>
                  </Stack>
                );
              })}
            </>
          )}
          {loading && (
            <>
              <Skeleton width={200} height={35} />
              <Skeleton width={200} height={35} />
              <Skeleton width={200} height={35} />
            </>
          )}
        </Stack>
      </Stack>
      )}
    </Box>
  );
};

SocialSection.defaultProps = {
  mediaList: [],
  loading: true,
};

SocialSection.propTypes = {
  mediaList: PropTypes.array,
  loading: PropTypes.bool,
};

export default SocialSection;

function CustomIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d={props.iconSvg} />
    </SvgIcon>
  );
}
