import React from "react";
import Typography from "@mui/material/Typography";
import { Stack, Paper, CircularProgress } from "@mui/material";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Chip from "@mui/material/Chip";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import { cancelTransaction, cancelSubscription } from "../../../config/api";
import { AuthContext } from "../../../App";

const DisplaySubscriptionInfo = ({
  state,
  setState,
  setTab,
  setOpenNewBusiness,
  setOpenCancelPet,
  setPetIndexCancel,
  setOpenCancelBusiness,
  refreshSubscriptions,
}) => {
  const initialCancelItem = {
    type: "",
    subscriptionId: "",
    transactionId: "",
    details: [],
  };
  const [openCancel, setOpenCancel] = React.useState(false);
  const [openPayModal, setOpenPayModal] = React.useState(false);
  const [cancelItem, setCancelItem] = React.useState(initialCancelItem);
  const handleClickOpen = () => {
    setOpenCancel(true);
  };
  const handleClose = () => {
    setOpenCancel(false);
  };
  const handleClosePayModal = () => {
    setOpenPayModal(false);
  };

  const authContext = React.useContext(AuthContext);

  const handleCancelItem = async () => {
    const token = authContext.auth.authItem.accessToken;
    if (state.requesting) {
      return;
    }

    setState((state) => ({
      ...state,
      requesting: true,
    }));

    const response =
      cancelItem.type === "cancelSubscription"
        ? await cancelSubscription(cancelItem.id, token)
        : await cancelTransaction(cancelItem.id, token);
    if (response.success) {
      await refreshSubscriptions;
      setState((state) => ({
        ...state,
        requesting: false,
        snackMessage: {
          display: true,
          message: "Your cancelation was successful",
          type: "success",
        },
      }));
    } else {
      setState((state) => ({
        ...state,
        requesting: false,
        snackMessage: {
          display: true,
          message:
            response.message ??
            "There was a problem canceling your transaction",
          type: "error",
        },
      }));
    }
    setCancelItem(initialCancelItem);
    setOpenCancel(false);
  };

  const toggleCheckout = (index) => {
    setState((state) => ({
      ...state,
      paying: true,
      subscriptionId: state.subscriptions[0].subscriptionId,
      payingItem: index,
    }));
  };

  const toggleRenew = (detailPending) => {
    if (detailPending) {
      setOpenPayModal(true);
    } else {
      setState((state) => ({
        ...state,
        paying: true,
        subscriptionId: state.subscriptions[0].subscriptionId,
        payingItem: null,
      }));
    }
  };

  const [openDetails, setOpenDetails] = React.useState(false);
  const [detailedSub, setDetailedSub] = React.useState({});
  const handleOpenDetails = () => {
    setOpenDetails(true);
  };
  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  const toggleDetail = (index) => {
    setDetailedSub(state.subscriptions[0].processedDetails[index]);
    handleOpenDetails();
  };

  const notification = state.subscriptions[0]?.notification;
  const refundError = state.subscriptions[0]?.refundError;
  const renewable = state.subscriptions[0]?.isRenewable ?? false;
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <>
      {refundError !== null && (
        <Alert severity="error" sx={{ mt: 3, px: 3, py: 2 }}>
          {refundError ?? "error on client response"}
        </Alert>
      )}
      {notification !== null && (
        <Grid container spacing={2} sx={{ alignItems: "center" }}>
          <Grid item xs={12} sx={{ alignItems: "center" }}>
            <Alert
              severity={notification.type ?? "error"}
              sx={{ mt: 3, px: 3, py: 2 }}
            >
              {notification.message ?? "error on client response"}
            </Alert>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2} sx={{ mt: 1, py: 1 }}>
        <Grid item xs={12}>
          {state.subscriptions?.map((sub, index) => {
            const detailPending = sub.detail.some(
              (detail) => detail.paidAt === null
            );
            const startDate = moment(sub.createdAt.split("T")[0]).format(
              "MM/DD/YYYY"
            );
            const endDate = moment(sub.expiresAt.split("T")[0]).format(
              "MM/DD/YYYY"
            );
            return (
              <Accordion key={sub.subscriptionId} defaultExpanded={index === 0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                    sx={{ mb: 1, fontWeight: "bold" }}
                  >
                    <Typography variant="body1">
                      <b>#{sub.subscriptionId}</b>
                    </Typography>
                    <Chip
                      label={`${startDate} - ${endDate}`}
                      color={sub.isExpired ? "default" : "primaryTk"}
                    />
                    {detailPending && <Chip label="Pending" color="chipW" />}
                    {renewable && <Chip label="Renewable" color="warning" />}
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 1 }}>
                      <TableBody>
                        {sub.processedDetails.map((detail, index) => {
                          return (
                            <TableRow
                              key={`${sub.subscriptionId}-${index}`}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>
                                {detail.title}
                                {matches && (
                                  <>
                                    <br /> <br />
                                    <Stack
                                      direction="row"
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      spacing={1}
                                    >
                                      {!detail.unpaid && (
                                        <>
                                          <Button
                                            color="primaryTk"
                                            variant="contained"
                                            onClick={() => {
                                              toggleCheckout(index);
                                            }}
                                            sx={{ mr: 2 }}
                                          >
                                            Checkout
                                          </Button>
                                          <Button
                                            color="primaryTk"
                                            variant="contained"
                                            onClick={() => {
                                              toggleDetail(index);
                                            }}
                                            sx={{ mr: 2 }}
                                          >
                                            Details
                                          </Button>
                                        </>
                                      )}
                                      {!sub.isExpired && detail.unpaid && (
                                        <Button
                                          color="primaryTk"
                                          variant="contained"
                                          onClick={() => {
                                            setCancelItem({
                                              type: detail.hasSubscription
                                                ? "cancelSubscription"
                                                : "cancelTransaction",
                                              id: detail.hasSubscription
                                                ? sub.subscriptionId
                                                : detail.details[0]
                                                    ?.transactionId ?? "",
                                            });
                                            handleClickOpen();
                                          }}
                                          sx={{ mr: 2 }}
                                        >
                                          {detail.hasSubscription && (
                                            <>Unsubscribe</>
                                          )}
                                          {!detail.hasSubscription && (
                                            <>Cancel</>
                                          )}
                                        </Button>
                                      )}
                                      {renewable && index === 0 && (
                                        <Button
                                          color="primaryTk"
                                          variant="contained"
                                          onClick={() =>
                                            toggleRenew(detailPending)
                                          }
                                          sx={{ mr: 2 }}
                                        >
                                          Renew
                                        </Button>
                                      )}
                                    </Stack>
                                  </>
                                )}
                              </TableCell>
                              {!matches && (
                                <TableCell>
                                  <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    {!sub.isExpired && detail.unpaid && (
                                      <>
                                        <Button
                                          color="primaryTk"
                                          variant="contained"
                                          onClick={() => {
                                            setCancelItem({
                                              type: detail.hasSubscription
                                                ? "cancelSubscription"
                                                : "cancelTransaction",
                                              id: detail.hasSubscription
                                                ? sub.subscriptionId
                                                : detail.details[0]
                                                    ?.transactionId ?? "",
                                            });
                                            handleClickOpen();
                                          }}
                                          sx={{ mr: 2 }}
                                        >
                                          {detail.hasSubscription && (
                                            <>Unsubscribe</>
                                          )}
                                          {!detail.hasSubscription && (
                                            <>Cancel</>
                                          )}
                                        </Button>
                                      </>
                                    )}
                                    {renewable && index === 0 && (
                                      <Button
                                        color="primaryTk"
                                        variant="contained"
                                        onClick={() =>
                                          toggleRenew(detailPending)
                                        }
                                        sx={{ mr: 2 }}
                                      >
                                        Renew
                                      </Button>
                                    )}
                                    {!detail.unpaid && (
                                      <>
                                        <Button
                                          color="primaryTk"
                                          variant="contained"
                                          onClick={() => {
                                            toggleCheckout(index);
                                          }}
                                          sx={{ my: 1, mr: 1 }}
                                        >
                                          Checkout
                                        </Button>
                                        <Button
                                          color="primaryTk"
                                          variant="contained"
                                          onClick={() => {
                                            toggleDetail(index);
                                          }}
                                          sx={{ my: 1, mr: 1 }}
                                        >
                                          Details
                                        </Button>
                                      </>
                                    )}
                                  </Stack>
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })}

                        {!sub.isExpired && !state.blockAll && (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell>
                              <Button
                                color="primaryTk"
                                variant="contained"
                                onClick={() => setTab(2)}
                                sx={{ mr: 1 }}
                              >
                                Add pet
                              </Button>
                              {!sub.hasBusinessCard && (
                                <Button
                                  color="primaryTk"
                                  variant="contained"
                                  onClick={() => setOpenNewBusiness(true)}
                                  sx={{ mr: 1 }}
                                >
                                  Add Business
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Grid>
      </Grid>
      <Dialog
        open={openPayModal}
        keepMounted
        onClose={handleClosePayModal}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Unable to renew yet"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Before renewing your subscription, please pay all Additionals
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primaryTk"
            onClick={handleClosePayModal}
          >
            Back
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCancel}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Would you like to cancel this purchase?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas
            minima eaque nesciunt. Dolores voluptatibus, consequatur eligendi
            nulla, earum mollitia tempore quae vel maiores tenetur quisquam
            repudiandae incidunt dolorem cumque, deserunt inventore beatae rem
            in repellat sequi dicta adipisci facere nobis. Voluptate veniam
            culpa voluptatibus, aut dignissimos vitae nostrum aliquam aperiam!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primaryTk" onClick={handleClose}>
            Back
          </Button>
          <Button
            onClick={handleCancelItem}
            variant="contained"
            color="primaryTk"
          >
            {!state.requesting && <>Proceed</>}
            {state.requesting && (
              <CircularProgress color="white" size="1.5rem" />
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDetails}
        maxWidth="xs"
        fullWidth
        keepMounted
        onClose={handleCloseDetails}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Typography
            variant="h5"
            component="h3"
            color="#33428C"
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              mt: 1,
              mb: 3,
            }}
          >
            Review your purchase
          </Typography>
          {detailedSub?.details && (
            <>
              {detailedSub.details.map((item, index) => {
                const itemType =
                  item.clientId !== null ? "Business Card" : "Pet Card";
                const subType =
                  item.type === "new"
                    ? "Subscription"
                    : item.type === "additional"
                    ? "Additional"
                    : item.type;
                const subPet = state.pets.find(
                  (pet) => pet.petId === item.petId
                );
                const petsIndex = state.pets.findIndex(
                  (pet) => pet.petId === item.petId
                );
                const petsName = subPet !== undefined ? ": " + subPet.name : "";
                const label = `${subType} - ${itemType}${petsName}`;
                return (
                  <DetailItem
                    key={item.clientId ?? item.petId}
                    label={label}
                    item={item}
                    index={index}
                    petIndex={petsIndex}
                    setOpenCancelPet={setOpenCancelPet}
                    setPetIndexCancel={setPetIndexCancel}
                    setOpenCancelBusiness={setOpenCancelBusiness}
                  />
                );
              })}
            </>
          )}
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center", mb: 2 }}>
          <Button
            variant="outlined"
            color="primaryTk"
            sx={{ borderRadius: "30px" }}
            onClick={handleCloseDetails}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DisplaySubscriptionInfo;

const DetailItem = ({
  label,
  item,
  index,
  setOpenCancelPet,
  setPetIndexCancel,
  petIndex,
  setOpenCancelBusiness,
}) => {
  const submitAction = () => {
    if (petIndex !== -1) {
      setPetIndexCancel(petIndex);
      setOpenCancelPet(true);
    } else {
      setOpenCancelBusiness(true);
    }
  };
  return (
    <Grid container alignItems="center" sx={{ mb: 2 }}>
      <Grid item xs={12}>
        <Stack
          direction={{ xs: "row", lg: "row" }}
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          sx={{ width: 1 }}
        >
          <Typography
            variant="h6"
            component="h3"
            sx={{ width: { xs: 0.7, lg: 1 } }}
            className="item-subtitle"
          >
            {label}
          </Typography>
          {item.type !== "new" && (
            <Button
              aria-label="delete"
              color="error"
              variant="outlined"
              onClick={submitAction}
            >
              <DeleteIcon />
            </Button>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};
