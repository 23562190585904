import React from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import HomeIcon from "@mui/icons-material/Home";
import WorkIcon from "@mui/icons-material/Work";
import Typography from "@mui/material/Typography";
import { Box, Stack, Link } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";

const PhoneSection = ({ phoneList, loading }) => {
  const iconSelect = (type) => {
    switch (type) {
      case "cell":
        return <PhoneIphoneIcon className="secondary-icon-color"/>;
      case "work":
        return <WorkIcon className="secondary-icon-color"/>;
      case "home":
        return <HomeIcon className="secondary-icon-color"/>;
      default:
        return <MoreHorizIcon className="secondary-icon-color"/>;
    }
  };
  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <PhoneIcon className="main-icon-color" />
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={ loading ? 0 : 1}
          sx={{pt:0.5}}
        >
          {!loading && (<>
            {phoneList.map((phone) => {
              return (
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={0.5}
                >
                  {iconSelect(phone.type)}
                  <Typography variant="body1" align="left" component="h3">
                    <Link href={`tel:${phone.value}`} underline="hover" color="inherit">{phone.value}</Link>
                  </Typography>
                </Stack>
              );
            })}
          </>)}
          {loading && (<>
            <Skeleton width={200} height={35}/>
            <Skeleton width={200} height={35}/>
            <Skeleton width={200} height={35}/>
          </>)}
        </Stack>
      </Stack>
    </Box>
  );
};

PhoneSection.defaultProps = {
  phoneList: [],
  loading: true,
};

PhoneSection.propTypes = {
  phoneList: PropTypes.array,
  loading: PropTypes.bool,
};

export default PhoneSection;
