import React, { useContext } from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { CheckoutContext } from "./Checkout";
import Alert from "@mui/material/Alert";

export default function Review({ handleOpen }) {
  const checkoutContext = useContext(CheckoutContext);
  const addresses1 = [
    checkoutContext.checkoutState.addresses[0].address1,
    checkoutContext.checkoutState.addresses[0].city,
    checkoutContext.checkoutState.addresses[0].stateAddress,
    checkoutContext.checkoutState.addresses[0].zip,
  ];
  const addresses2 = [
    checkoutContext.checkoutState.addresses[1].address1,
    checkoutContext.checkoutState.addresses[1].city,
    checkoutContext.checkoutState.addresses[1].stateAddress,
    checkoutContext.checkoutState.addresses[1].zip,
  ];
  const addresses3 = [
    checkoutContext.checkoutState.addresses[2].address1,
    checkoutContext.checkoutState.addresses[2].city,
    checkoutContext.checkoutState.addresses[2].stateAddress,
    checkoutContext.checkoutState.addresses[2].zip,
  ];
  const handleAcceptTerms = () => {
    const value = !checkoutContext.checkoutState.termsCheck;
    checkoutContext.setCheckoutState({
      ...checkoutContext.checkoutState,
      termsCheck: value,
      snackMessage: {
        display: false,
        message: "",
        type: "info",
      },
    });
  };

  let total = 0;
  const productsArray = [...checkoutContext.checkoutState.products];
  const index = productsArray.findIndex((element) => element.added === true);
  const additionalsQty = productsArray[index].businessCard
    ? productsArray[index].petQty
    : productsArray[index].petQty - 1;
  const additionalsPrice =
    additionalsQty * checkoutContext.checkoutState.prices.additional;

  if (productsArray[index].businessCard) {
    total += checkoutContext.checkoutState.prices.subscription;
    total += additionalsPrice;
  } else {
    total += checkoutContext.checkoutState.prices.subscription;
    total += additionalsPrice;
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <List disablePadding>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText
            primary={`Subscription: ${productsArray[index].label}`}
            secondary={`${
              productsArray[index].businessCard ? "Business Card" : "Pet Card"
            } x1`}
          />
          <Typography variant="body2">
            $
            {parseFloat(
              checkoutContext.checkoutState.prices.subscription
            ).toFixed(2)}
          </Typography>
        </ListItem>
        {additionalsQty > 0 && (
          <ListItem sx={{ py: 1, px: 0 }}>
            <ListItemText
              primary="Additional"
              secondary={`Pet Card x${additionalsQty}`}
            />
            <Typography variant="body2">
              ${parseFloat(additionalsPrice).toFixed(2)}
            </Typography>
          </ListItem>
        )}
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            ${parseFloat(total).toFixed(2)}
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Business Address
          </Typography>
          <Typography
            gutterBottom
          >{`${checkoutContext.checkoutState.client.firstName} ${checkoutContext.checkoutState.client.lastName}`}</Typography>
          <Typography gutterBottom>{addresses1.join(", ")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Shipping Address
          </Typography>
          <Typography
            gutterBottom
          >{`${checkoutContext.checkoutState.client.firstName} ${checkoutContext.checkoutState.client.lastName}`}</Typography>
          <Typography gutterBottom>{addresses2.join(", ")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Billing Address
          </Typography>
          <Typography
            gutterBottom
          >{`${checkoutContext.checkoutState.client.firstName} ${checkoutContext.checkoutState.client.lastName}`}</Typography>
          <Typography gutterBottom>{addresses3.join(", ")}</Typography>
        </Grid>
        
        <Grid item xs={12}>
          <Box>
            <Checkbox
              onChange={handleAcceptTerms}
              checked={checkoutContext.checkoutState.termsCheck}
              color="secondary"
              name="saveAddress"
            />
            <span>
              I agree with the{" "}
              <Button variant="text" onClick={handleOpen}>
                Terms and Conditions
              </Button>
            </span>
          </Box>
        </Grid>
      </Grid>
      
      {checkoutContext.checkoutState.snackMessage.display && (
        <Alert severity={checkoutContext.checkoutState.snackMessage.type}>
          {checkoutContext.checkoutState.snackMessage.message}
        </Alert>
      )}
    </React.Fragment>
  );
}
